<template>
  <div>
    <template v-for="(category, categoryIndex) in entity.categories">
      <div
        v-if="category"
        :key="category.id"
        class="tw-my-2 tw-bg-white"
      >
        <div class="tw-mx-2 tw-flex tw-justify-between tw-items-baseline">
          <div>
            <h2 v-if="showCategoryName" class="tw-mt-2">{{ category.name }}</h2>
          </div>
          <div class="tw-group tw-relative tw--mb-4">
            <button
              type="button"
              title="Toevoegen nieuwe taak"
              class="tw-px-1 tw-rounded hover:tw-bg-gray-e9"
              @click="showCreateCustomTodo(category.id, { categoryIndex })"
            >
              <i class="fas fa-plus" />
            </button>
            <button
              type="button"
              title="Meer acties"
              class="tw-px-1 tw-rounded hover:tw-bg-gray-e9"
              @click="toggleActionsMenu(category.id)"
            >
              <i class="fas fa-ellipsis-h" />
            </button>
            <ul
              v-show="actionsMenu[category.id]"
              :class="[
                'tw-absolute tw-text-right tw-bg-white',
                'tw-rounded tw-right-0 tw-whitespace-nowrap tw-overflow-hidden tw-z-40 tw-shadow-card'
              ]"
            >
              <li
                v-for="{ label, action, onlyVisible } in MARK_ALL_OPTIONS"
                :key="`${category.id}_${action}`"
                class="hover:tw-bg-gray-e9"
              >
                <button
                  type="button"
                  :title="label"
                  class="tw-px-2 tw-py-1 tw-text-tg-color"
                  @click="markAll(categoryIndex, action, onlyVisible)"
                >
                  {{ label }}
                </button>
              </li>
              <li class="hover:tw-bg-gray-e9">
                <button
                  type="button"
                  title="Openstaande items versturen via e-mail"
                  class="tw-px-2 tw-py-1 tw-text-tg-color"
                  @click="sendChecklistNotification(entity, category.id)"
                >
                  Openstaande items versturen via e-mail
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="tw-p-2 tw-pt-1 tw-grid md:tw-grid-cols-3 tw-gap-2 tw-bg-white">
          <div v-for="(todos, column) in category.todos" :key="column" class="md:tw-h-full">
            <h3 class="tw-block md:tw-hidden tw-mt-0">{{ CHECKLIST_COLUMN_TITLES[column] }}</h3>
            <div
              class="tw-px-2 tw-py-1 tw-bg-success tw-bg-opacity-10 tw-shadow-sm tw-min-h-[60px] md:tw-h-full"
              @dragover.prevent
              @drop.prevent="onDrop($event, column)"
            >
              <transition-group name="fade" mode="out-in">
                <div
                  v-for="({ file_type, ...todo }, todoIndex) in todos"
                  :key="todo.id"
                  :draggable="!updatingTodo[todo.id]"
                  :class="[
                    { 'tw-pointer-events-none': updatingTodo[todo.id] },
                    { 'tw-hidden': !showNa && !!todo.date_na },
                    { 'tw-hidden': showOnlyAgent && parseInt(todo.user) === 1 },
                    { 'tw-hidden': showOnlySalesSupport && parseInt(todo.user) === 0 },
                    'tw-my-2 tw-p-2 tw-bg-white tw-shadow-card'
                  ]"
                  @dragstart="onDragStart($event, todo, column, { todoIndex, categoryIndex })"
                >
                  <details :open="expanded" :class="{ 'tw-animate-pulse': updatingTodo[todo.id] }">
                    <summary :title="todo.description || todo.title" class="tw-cursor-pointer tw-relative">
                      <!-- 95% width and all the inline crap is a hack to display the summary arrow correctly -->
                      <div class="tw-inline-flex tw-justify-between tw-gap-2 tw-items-baseline tw-w-full tw-max-w-[93%] xl:tw-max-w-[96%] 2xl:tw-max-w-[97%]">
                        <h4
                          :class="[
                            'tw-m-0',
                            { 'tw-text-gray-cc': !!todo.date_na }
                          ]"
                        >
                          {{ todo.title }}
                        </h4>
                        <button
                          type="button"
                          title="Bewerken"
                          class="btn btn-xs btn-default"
                          @click="showEdit({ todo, column, categoryIndex, todoIndex })"
                        >
                          <i class="far fa-pencil" />
                        </button>
                      </div>
                      <!-- 172800000 represents 2 days -->
                      <Tooltip
                        v-if="todo.date_expiration && !todo.date_finished"
                        :class="[
                          (new Date(todo.date_expiration) - new Date()) > 172800000 ? 'tw-bg-warning' : 'tw-bg-danger',
                          'tw-absolute tw--top-3 tw--left-3 tw-px-0.5 tw-rounded-full tw-text-white tw-text-xs tw-shadow-sm'
                        ]"
                      >
                        <i class="fas fa-clock" />
                        <template #popper>
                          Vervaldatum: {{ todo.date_expiration | date-day }}
                        </template>
                      </Tooltip>
                    </summary>

                    <div class="tw-pl-2">
                      <div class="tw-flex tw-flex-wrap tw-justify-between tw-gap-2 tw-w-full tw-mt-1">
                        <div class="tw-flex-shrink">
                          <div class="tw-my-2 tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
                            <button
                              type="button"
                              :key="`${todo.id}_nvt`"
                              :class="[
                                { 'tw-text-white tw-bg-success': !!todo.date_na },
                                'tw-px-2 tw-rounded-full tw-border'
                              ]"
                              @click="markNa({ todo, column, categoryIndex, todoIndex })"
                            >
                              N.v.t.
                            </button>
                            <button
                              v-if="todo.user === null && !todo.order_type"
                              type="button"
                              class="action tw-rounded-full tw-bg-danger tw-px-2 tw-py-0"
                              @click="deleteTodo({ todo, categoryIndex, column, todoIndex })"
                            >
                              Verwijderen
                            </button>
                            <template v-else>
                              <!-- We do a loose comparison, because type of the user changes from string to int -->
                              <button
                                v-for="(label, user) in { 0: 'Makelaar', 1: 'Sales support' }"
                                :key="`${todo.id}_${label}`"
                                type="button"
                                :class="[
                                  { 'tw-text-white tw-bg-success': todo.user == user },
                                  'tw-px-2 tw-rounded-full tw-border'
                                ]"
                                @click="changeUser({ todo, categoryIndex, column, todoIndex }, { user })"
                              >
                                {{ label }}
                              </button>
                            </template>
                            <!-- From Jan: Often we have to re-order for Realsmart, even though order was already sent -->
                            <button
                              v-if="todo.order_type"
                              type="button"
                              :disabled="todo.date_started && !['legal', 'vip', 'asbestos'].includes(todo.order_type)"
                              class="action tw-rounded-full tw-bg-warning tw-px-2 tw-py-0"
                              @click="openOrderModal({ todo, column, categoryIndex, todoIndex })"
                            >
                              <i class="far fa-shopping-cart tw-mr-1" />
                              Bestellen
                            </button>
                          </div>
                          <div class="tw-my-2 tw-items-start" v-if="todo.order_status">
                            <span class="tw-rounded-full tw-border tw-px-2 tw-py-0 tw-bg-gray-100">
                              <strong>Status: {{ todo.order_status }}</strong>
                            </span>
                          </div>
                          <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-start">
                            <span v-if="todo.date_started" class="pill">
                              Aangevraagd: {{ todo.date_started | date-day }}
                            </span>
                            <span v-if="todo.date_expiration" class="pill">
                              Vervaldatum: {{ todo.date_expiration | date-day }}
                            </span>
                            <span v-if="todo.date_finished" class="pill">
                              Afgewerkt: {{ todo.date_finished | date-day }}
                            </span>
                          </div>
                        </div>

                        <FileDropzone
                          v-if="file_type"
                          :url="fileUploadUrl"
                          :is-entity-file-upload="true"
                          class="tw-flex-grow tw-mr-2 2xl:tw-mr-1.5 2xl:tw-max-w-[200px] tw-w-full"
                          @file-uploaded="
                            fileUploaded(
                              $event,
                              { file_type },
                              todo,
                              { prevColumn: column, newColumn: 'done' },
                              { categoryIndex, todoIndex }
                            )
                          "
                        >
                          <template #droparea="{ loading }">
                            <div
                              :class="[
                                'tw-px-4 tw-py-2 tw-w-full tw-h-full tw-grid tw-place-items-center tw-border tw-border-dashed tw-border-tg-color',
                                { 'tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100': !loading }
                              ]"
                            >
                              <i
                                :class="[
                                  'fal fa-2x tw-pointer-events-none',
                                  loading ? 'fa-spinner-third fa-spin' : 'fa-file-upload'
                                ]"
                              />
                            </div>
                          </template>
                        </FileDropzone>
                      </div>

                      <p v-if="todo.notes" class="tw-my-2 tw-px-1 tw-italic tw-whitespace-pre-wrap">{{ todo.notes }}</p>
                    </div>
                  </details>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </template>

    <BaseModal
      ref="editCreateTodo"
      :title="newTask ? 'Toevoegen nieuwe taak' : 'Bewerken'"
      max-width="tw-max-w-sm"
    >
      <FormulateForm
        #default="{ isLoading }"
        v-model="edit"
        name="editCreateTodo"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @submit="(newTask ? createCustomTodo : editTodo)($event)"
      >
        <FormulateInput
          v-if="newTask || edit.user === null"
          type="text"
          name="title"
          label="Titel"
          placeholder="Titel"
          validation="required:trim"
          :outer-class="['tw-mt-0']"
        />
        <FormulateInput
          v-if="newTask"
          type="select"
          name="order_type"
          label="Order type"
          :options="customTaskOptions"
          :outer-class="['tw-mt-0']"
        />
        <FormulateInput
          type="date"
          name="date_expiration"
          label="Vervaldatum"
          placeholder="YYYY-MM-DD"
          validation="bail|optional|date:YYYY-MM-DD"
          :outer-class="['tw-mt-0']"
        />
        <FormulateInput
          type="textarea"
          name="notes"
          label="Commentaar"
        />

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
    <LegalOrderModal
      ref="legalOrderModal"
      :todo="orderTodo"
      :property="entity"
      :baseRegistersData="entity.base_registers_data"
      @orderPlaced="orderHandler"
      @orderResend="orderResendHandler"
    />
    <CadastralOrderModal
      ref="cadastralOrderModal"
      :todo="orderTodo"
      @orderPlaced="orderHandler"
      @orderResend="orderResendHandler"
    />
    <CertificationOrderModal
      ref="certificationOrderModal"
      :entity="entity"
      @completed="orderCompleted"
    />
    <SmoovedOrderModal
      ref="smoovedOrderModal"
      :entity="entity"
      :todo="orderTodo"
      @completed="orderCompleted"
    />
    <VipOrderModal
      v-if="entityType === 'property'"
      ref="vipOrderModal"
      :property="entity"
      :todo="orderTodo"
      @completed="orderCompleted"
    />
    <EccaOrderModal
      v-if="entityType === 'property'"
      ref="eccaOrderModal"
      :property="entity"
      :todo="orderTodo"
      @completed="orderCompleted"
    />
  </div>
</template>

<script>
import format from 'date-fns/format'
import { Tooltip } from 'floating-vue'
import { CHECKLIST_COLUMN_TITLES } from '@/utils/helpers'

import FileDropzone from '@/components/iam/FileDropzone'
import VipOrderModal from '@/components/orders/VipOrderModal'
import EccaOrderModal from '@/components/orders/EccaOrderModal'
import LegalOrderModal from '@/components/orders/LegalOrderModal'
import SmoovedOrderModal from '@/components/orders/SmoovedOrderModal'
import CadastralOrderModal from '@/components/orders/CadastralOrderModal'
import CertificationOrderModal from '@/components/orders/CertificationOrderModal'

import {
  moveTodo,
  updateTodo,
  deleteTodo,
  propertyBulkEditTodos,
  projectBulkEditTodos,
  sendPropertyChecklistNotification,
  sendProjectChecklistNotification,
  propertyCreateCustomTodo,
  projectCreateCustomTodo
} from '@/services/checklists'
import { createProjectFile } from '@/services/projects'
import { createPropertyFile } from '@/services/properties'
import { resendOrder, placeBulkOrder } from '@/services/orders'
import { startLoadingModal, successModal, errorModal, stopLoadingModal } from '@/modalMessages'

export default {
  name: 'ChecklistKanban',
  components: {
    Tooltip,
    FileDropzone,
    VipOrderModal,
    EccaOrderModal,
    LegalOrderModal,
    SmoovedOrderModal,
    CadastralOrderModal,
    CertificationOrderModal
  },
  props: {
    entity: {
      type: Object,
      required: true
    },
    showNa: {
      // Include the nvt tasks
      type: Boolean,
      default: false
    },
    showCategoryName: {
      type: Boolean,
      default: true
    },
    expanded: {
      // expand all the tickets
      type: Boolean,
      default: false
    },
    showOnlySalesSupport: {
      // Show only the sales support tickets
      type: Boolean,
      default: false
    },
    showOnlyAgent: {
      // Show only the makelaar tickets
      type: Boolean,
      default: false
    }
  },
  constants: {
    CHECKLIST_COLUMN_TITLES,
    MARK_ALL_OPTIONS: [
      { label: 'Alles van toepassing', action: 'set_applicable', onlyVisible: false },
      { label: 'Alles niet van toepassing', action: 'set_not_applicable', onlyVisible: false },
      { label: 'Alles makelaar', action: 'set_agent', onlyVisible: true },
      { label: 'Alles sales support', action: 'set_sales_support', onlyVisible: true }
    ]
  },
  data () {
    return {
      edit: {},
      editMeta: {},
      updatingTodo: {},
      orderTodo: {},
      orderMeta: {},
      actionsMenu: {},
      newTask: false
    }
  },
  computed: {
    entityType () {
      return this.$route.meta.entity_type
    },
    fileUploadUrl () {
      const url = this.entityType === 'property'
        ? `/api/v3/property/${this.entity.id}/files/upload`
        : `/api/v3/project/${this.entity.id}/files/upload`
      return url
    },
    createEntityFile () {
      return this.entityType === 'property' ? createPropertyFile : createProjectFile
    },
    customTaskOptions () {
      return {
        '': 'Persoonlijke taak',
        vip: 'VIP',
        asbestos: 'Asbestinventaris (Ecca)'
      }
    }
  },
  methods: {
    showCreateCustomTodo (category_id, indices) {
      this.newTask = true
      this.edit = { category_id, indices }
      this.$refs.editCreateTodo.show()
    },
    hideCreateCustomTodo () {
      this.newTask = false
      this.$refs.editCreateTodo.hide()
    },
    toggleActionsMenu (categoryId) {
      this.$set(this.actionsMenu, categoryId, !this.actionsMenu[categoryId])
    },
    hideEdit () {
      this.$refs.editCreateTodo.hide()
      this.edit = {}
    },
    showEdit (meta) {
      this.newTask = false
      const { todo, column, categoryIndex, todoIndex } = meta
      const { id, date_expiration, notes, user, title, order_type } = todo
      const date = date_expiration
        ? format(new Date(date_expiration), 'yyyy-MM-dd')
        : null
      this.edit = {
        id,
        user,
        notes,
        title,
        order_type,
        date_expiration: date
      }
      this.editMeta = { column, categoryIndex, todoIndex }
      this.$refs.editCreateTodo.show()
    },
    openOrderModal (payload) {
      const { todo, ...meta } = payload
      this.orderTodo = todo
      this.orderMeta = meta
      // Wait for the prop, component and the vue DOM to update
      this.$nextTick(() => {
        switch (todo.order_type) {
          case 'cadastral':
            return this.$refs.cadastralOrderModal.show()
          case 'legal':
            if (!this.entity?.parcels?.length) return errorModal('Er zijn geen percelen geselecteerd.')
            return this.$refs.legalOrderModal.show()
          case 'certifications':
            this.$refs.certificationOrderModal.todoId = todo.id
            return this.$refs.certificationOrderModal.show()
          case 'move':
            return this.$refs.smoovedOrderModal.show()
          case 'vip':
            return this.$refs.vipOrderModal.show()
          case 'asbestos':
            return this.$refs.eccaOrderModal.show()
          default:
            return false
        }
      })
    },
    removeTodo (indices, column) {
      // We use a combination of indices and the column name to traverse down the lists to find the todo and remove it from it's previous column.
      const { todoIndex, categoryIndex } = indices
      this.entity.categories[categoryIndex].todos[column].splice(todoIndex, 1)
    },
    setTodo (todo, indices, column, mode = 'unshift') {
      // We use a combination of indices and the column name to traverse down the lists to find the todo and remove it from it's previous column.
      const { categoryIndex } = indices
      this.entity.categories[categoryIndex].todos[column][mode](todo)
    },
    onDragStart (event, todo, column, indices) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('column', column)
      event.dataTransfer.setData('todoId', todo.id)
      event.dataTransfer.setData('indices', JSON.stringify(indices))
      event.dataTransfer.setData('entityId', this.entity.id)
    },
    async onDrop (event, newColumn) {
      const todoId = event.dataTransfer.getData('todoId')
      const prevColumn = event.dataTransfer.getData('column')
      const indices = JSON.parse(event.dataTransfer.getData('indices'))
      const entityId = parseInt(event.dataTransfer.getData('entityId'))

      if (this.entity.id !== entityId) return // Don't allow moving across properties
      if (prevColumn === newColumn) return // Don't allow dropping on the same column
      this.$set(this.updatingTodo, todoId, true)
      const response = await this.moveTodo(todoId, { newColumn, prevColumn }, indices)
      this.$set(this.updatingTodo, todoId, false)
      return response
    },
    async moveTodo (todoId, columns, indices) {
      const { newColumn, prevColumn } = columns
      const response = await moveTodo(todoId, { action: newColumn })
      this.setTodo(response.data, indices, newColumn, 'push')
      this.removeTodo(indices, prevColumn)
      return response
    },
    async markNa (meta) {
      const { todo, column, categoryIndex, todoIndex } = meta
      this.$set(this.updatingTodo, todo.id, true)

      const action = todo.date_na ? 'to_do' : 'not_applicable'
      const response = await moveTodo(todo.id, { action })
      this.entity.categories[categoryIndex].todos[column].splice(todoIndex, 1)
      this.entity.categories[categoryIndex].todos.to_do.push(response.data) // All nvt tasks end up in todo, regardless if they are set or unset.

      this.$set(this.updatingTodo, todo.id, false)
      return response.data
    },
    async changeUser (meta, payload) {
      const { todo, categoryIndex, column, todoIndex } = meta
      const response = await updateTodo(todo.id, payload)
      this.entity.categories[categoryIndex].todos[column].splice(todoIndex, 1, response.data)
      return response
    },
    async editTodo (data) {
      try {
        const { id, date_expiration, ...payload } = data
        const { column, categoryIndex, todoIndex } = this.editMeta
        payload.date_expiration = date_expiration ? new Date(date_expiration) : null
        const response = await updateTodo(id, payload)
        // Update the original todo after the PATCH operation.
        this.$set(this.entity.categories[categoryIndex].todos[column], todoIndex, response.data)
        this.hideEdit()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'editTodo')
      }
    },
    async orderHandler (orders) {
      try {
        startLoadingModal('De bestelling wordt verwerkt.')
        const payload = {
          orders: orders.map(order => ({
            todo_id: this.orderTodo.id,
            type_id: 3, // 3 = RealSmart, 1 = Legacy RealSmart
            data: order
          }))
        }
        const response = await placeBulkOrder(payload)
        await this.orderCompleted()
        successModal('De bestelling werd succesvol aangemaakt.')
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het aanmaken van je bestelling.')
        console.error(error)
      }
    },
    async orderResendHandler (order) {
      try {
        startLoadingModal('De bestelling wordt opnieuw verstuurd.')
        const response = await resendOrder(order.id)
        stopLoadingModal()
        successModal('De bestelling werd succesvol opnieuw verstuurd.')
        return response
      } catch (error) {
        console.log(error)
        errorModal('Er ging iets mis bij het opnieuw versturen van je bestelling.')
      }
    },
    async orderCompleted () {
      this.$set(this.updatingTodo, this.orderTodo.id, true)
      const { categoryIndex, todoIndex, column } = this.orderMeta
      const response = await moveTodo(this.orderTodo.id, { action: 'in_progress' })
      this.setTodo(response.data, { categoryIndex }, 'in_progress')
      this.removeTodo({ categoryIndex, todoIndex }, column)
      this.$set(this.updatingTodo, this.orderTodo.id, false)
    },
    async markAll (categoryIndex, action, onlyVisible = true) {
      // If NVT todos are not shown, and we want to operate on only visible todos, we should store the hidden todos
      // They can then be included and patched to the category todos, as the response will only include the todo ids that were sent.
      // This is important to do as hidden todos would get lost otherwise.
      const todos = []
      const hidden_todos = []
      const category = this.entity.categories[categoryIndex]

      Object.values(category.todos).flat().forEach(todo => {
        const isHiddenNvt = !this.showNa && todo.date_na
        const isHiddenAgent = this.showOnlySalesSupport && parseInt(todo.user) === 0
        const isHiddenSalesSupport = this.showOnlyAgent && parseInt(todo.user) === 1
        const isHidden = isHiddenSalesSupport || isHiddenAgent || isHiddenNvt
        if (onlyVisible && isHidden) hidden_todos.push(todo)
        else todos.push(todo)
      })

      const todo_ids = todos.map(todo => todo.id)
      const bulkUpdate = this.entityType === 'property'
        ? propertyBulkEditTodos
        : projectBulkEditTodos

      const response = await bulkUpdate(this.entity.id, { todo_ids, action })
      const { to_do, in_progress, done } = response.data
      this.$set(category, 'todos', {
        to_do: [...to_do, ...hidden_todos],
        in_progress,
        done
      })

      successModal('Alle taken van de categorie zijn succesvol gemarkeerd.')
      return response
    },
    async sendChecklistNotification (entity, category_id) {
      const response = await (this.entityType === 'property'
        ? sendPropertyChecklistNotification
        : sendProjectChecklistNotification
      )(entity.id, { category_id })
      successModal('Een e-mail werd verstuurd naar de makelaar en sales support(s).', false)
      return response
    },
    async createCustomTodo (data) {
      const { indices, date_expiration, ...payload } = data
      payload.date_expiration = date_expiration ? new Date(date_expiration) : null
      // This is temporary until we implement the selection of an order type
      payload.is_personal = true
      const response = await (this.entityType === 'property'
        ? propertyCreateCustomTodo
        : projectCreateCustomTodo
      )(this.entity.id, payload)
      this.setTodo(response.data, indices, 'to_do')
      this.hideCreateCustomTodo()
      successModal('Todo is aangemaakt')
      return response
    },
    async deleteTodo (meta) {
      const { todo, categoryIndex, column, todoIndex } = meta
      this.$set(this.updatingTodo, todo.id, true)
      const response = await deleteTodo(todo.id)
      this.removeTodo({ todoIndex, categoryIndex }, column)
      this.$set(this.updatingTodo, todo.id, false)
      return response
    },
    async fileUploaded (file, payload, ...moveArgs) {
      const { key, filename } = file
      const response = await this.createEntityFile(this.entity.id, { key, filename, ...payload })
      await this.moveTodo(...moveArgs)
      successModal(`Bestand '${response.data?.filename} is toegevoegd`)
      return response
    }
  }
}
</script>

<style scoped>
.pill {
  @apply tw-border tw-rounded-full tw-text-xs tw-px-2 tw-py-0.5 tw-bg-gray-100;
}
</style>
